<template>
  <CFooter>
    <div>
      <a href="http://www.kwaste.or.kr/" target="_blank">KAWET</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} 한국폐자원에너지기술협의회</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank"></span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
