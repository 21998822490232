<template>
  <component :is="$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
@import './assets/css/variable.css';
</style>
