export default [
  {
    component: 'CNavTitle',
    name: '업무',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '총괄',
    to: '/total',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '인력양성',
    to: '/education',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '교과목',
    to: '/course',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '교재개발',
    to: '/textbook',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '산학연계강좌',
    to: '/lecture',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '인턴쉽',
    to: '/internship',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '산학공동기초연구',
    to: '/research',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '현장실습 및 견학',
    to: '/practice',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '논문',
    to: '/thesis',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '학술발표',
    to: '/conference',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '산학공동프로젝트',
    to: '/project',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '기타실적',
    to: '/result',
    icon: 'cil-spreadsheet',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavTitle',
    name: '기준정보',
    meta: { minauth: 1 },
  },
  {
    component: 'CNavItem',
    name: '사업단 관리',
    to: '/userinfo',
    icon: 'cil-education',
    meta: { minauth: 30 },
  },
  {
    component: 'CNavItem',
    name: '학생 관리',
    to: '/scholarinfo',
    icon: 'cil-education',
    meta: { minauth: 30 },
  }
]
